.menu-headline {
    margin-top: 1.5rem;
    margin-bottom: 1.25rem;
}

.menu-headline .well {
    background-color: rgba(255, 255, 255, .4);
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.menu-headline .well .card-body {
    padding-top: .25rem;
    padding-bottom: .25rem;
    font-size: 1.5rem;
}

/* Portrait phones and smaller */
@media (max-width: 767px) {
    .menu-headline {
        margin-top: .5rem;
        margin-bottom: .5rem;
        flex: 1;
        padding-left: 16px; /* to match navbar */
        padding-right: 16px;
    }
}
