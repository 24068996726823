.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 15px;
}

.proj-row {
  padding-bottom: 30px;
}

.lg-project {
  width: 50%;
  padding-left: 15px;
  padding-right: 15px;
}

/*.proj-row:not(:last-child) {*/
/*  padding-bottom: 30px;*/
/*}*/

.project {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 1.9rem;
  background-color: rgb(245, 245, 245);
  padding: 1rem;
}

.section {
  margin: 0 0 10px;
}

.section.project-heading {
  /*flex-grow: 1;*/
  font-size: 1.4rem;
}

.project-description {
  margin-top: 30px;
  margin-bottom: 20px;
  margin-right: 60px;
  margin-left: 60px;
  flex-grow: 1;
}

.project-tech {
  margin-top: 10px;
  gap: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.project-tech .label {
  display: inline-block;
}

.hide-on-lg {
  display: none;
}

/****************** MEDIA QUERIES FOR DIFFERENT SCREEN SIZES *****************/

@media (max-width: 999px) {
  .hide-on-lg {
    display: inherit;
  }

  .hide-on-sm {
    display: none;
  }

  .project {
    margin-bottom: 0;
  }

  .sm-project {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (max-width: 767px) {
  .project-description {
    margin-left: 10px;
    margin-right: 10px;
  }

}
