.simple-row {
    display: flex;
    flex-direction: row;
}

.simple-col {
    display: flex;
    flex-direction: column;
}

.simple-badge {
    background-color: #777;
    color: #fff;
    border-radius: 3.375px;
    box-sizing: border-box;
    display: inline-block;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
}
