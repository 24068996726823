.ultra {
  font-family: 'Ultra', Times New Roman, Times, serif;
}

.cinzel {
  font-family: 'Cinzel Decorative', cursive;
}

.bai {
  font-family: 'Bai Jamjuree', sans-serif;
}

.mono, .nova-mono {
  font-family: 'Nova Mono', monospace;
}
