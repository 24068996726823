.navbar {
    font-size: 1.5rem;
    /*box-shadow: inset 0 1px 0 rgba(255,255,255,.15), 0 1px 5px rgba(0,0,0,.075);*/
    /*-webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.15), 0 1px 5px rgba(0,0,0,.075);*/
    /*z-index: 1030;*/
}

header .menu-panel .navbar.fixed-top {
    /*padding-top: 1rem;*/
}

.navbar .container {
    justify-content: center;
}

.navbar .container .navbar-nav > div:not(:first-child) {
    margin-left: 100px;
}

.navbar-light .navbar-nav .nav-item a {
    color: #000000;
}

.navbar-light .navbar-nav .nav-item a:hover {
    color: #000000;
    text-decoration-line: none;
}

.navbar-nav > div > a {
    text-shadow: rgba(255, 255, 255, .25) 1px 1px 1px;
}

.navbar .container .navbar-nav > div > a:hover {
    color: #ffffff;
    text-shadow: rgba(0, 0, 0, .25) 1px 1px 1px;
}

/* Portrait phones and smaller */
@media (max-width: 767px) {

    .menu-panel {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .navbar.ultra {
        flex: 1;
    }

    .navbar .container .navbar-nav > div:not(:first-child) {
        margin-left: 0px;
    }

    .navbar .container {
        padding-top: 0rem;
    }

    /*.navbar-default .navbar-nav>div>a {*/
    /*    padding-top: 0px;*/
    /*    padding-bottom: 0px;*/
    /*}*/

    /*.menuHeadline {*/
    /*    margin-top: 0px;*/
    /*}*/
}
