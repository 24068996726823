body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

#root {
  height: 100vh;
}

