.main-headings {
  color: #fff;
  text-shadow: 0 .1em .2em rgba(0,0,0,.6);
  margin-top: 45vh;
}

.mainName {
  font-size: 5rem;
}

.mainTitle {
  font-size: 4rem;
  margin-top: -.25rem;
}

/****************** MEDIA QUERIES FOR DIFFERENT SCREEN SIZES *****************/

@media (max-width: 768px) {
  .main-headings {
    margin-top: 40vh;
  }

  .mainName {
    font-size: 3.5rem;
  }
  .mainTitle {
    font-size: 2.5rem;
  }
}


/* Portrait phones and smaller */
@media (max-width: 480px) {
  .main-headings {
    margin-top: 40vh;
  }
  .mainName {
    font-size: 3rem;
  }
  .mainTitle {
    font-size: 2.25rem;
  }
}
