body {
  text-align: center;
  overflow: auto;
  background: black url('./dinos-horiz-opt.jpg') fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; /* Fill entire page */
}

.page-wrapper {
  /*height: 100vh;*/
  padding-top: 3vh;
  overflow: auto;
}

.menu-panel {
  background-color: rgba(255, 255, 255, .4);
  /*position: fixed;*/
  /*top: 0;*/
  /*right: 0;*/
  /*left: 0;*/
  padding-top: 1rem;
  padding-bottom: 1rem;
}

/****************** MEDIA QUERIES FOR DIFFERENT SCREEN SIZES *****************/

@media (max-width: 767px) {
  .page-wrapper {
    padding-top: 5vh;
  }
}
