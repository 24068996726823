.portrait {
  border-radius: 50%;
  height: 35vh;
  padding-left: 1rem;
  padding-right: 1rem;
}

.portrait-frame {
  margin-left: auto;
  margin-right: auto;
}

.contact-panel {
  background-color: rgba(255, 255, 255, .6) !important;
  font-size: 1.9rem;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  padding: 2rem;
  display: flex;
  flex-direction: row;
  max-width: 85%;
  flex-wrap: wrap;
}

.contact-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.fa.inner {
  color: white;
}

.button-row {
  justify-content: center;
  display: flex;
  margin-bottom: 5vh;
}

.button-row a:not(:first-child) {
  margin-left: .5rem;
}

.contact-panel button {
  font-size: 1.25rem;
  /* Copied from old school react-bootstrap Button */
  text-shadow: 0 1px 0 #fff;
  background-image: linear-gradient(to bottom, #fff 0, #e0e0e0 100%);
  background-repeat: repeat-x;
  padding: .4rem .8rem;
  padding-bottom: .8rem;
  margin-bottom: 0;
  touch-action: manipulation;
  border: 1px solid transparent;
  border-radius: 4px;
}

.contact-panel a:hover {
  outline: 0;
}

.contact-panel button:hover {
  background-color: #e0e0e0;
  background-position: 0 -15px;
  color: #333;
  border-color: #adadad;
}

.email {
  font-size: 1.25rem;
  text-align: center;
}

.email a {
  color: #ab4455;
}

.info {
  margin-bottom: 1.4rem;
  font-size: 1.5rem;
}

/****************** MEDIA QUERIES FOR DIFFERENT SCREEN SIZES *****************/

@media (max-width: 767px) {

  .contact-panel {
    max-width: 90vw;
  }

  .contact-panel button {
    font-size: 1.2rem;
  }

  button {
    padding-right: 6px;
    padding-left: 6px;
  }

  img.portrait {
    margin-top: 20px;
  }
}
